import { Injectable } from '@angular/core';
import { Observable, Subject, Subscription, forkJoin, interval, lastValueFrom } from 'rxjs';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { Mappedin } from '@mappedin/mappedin-js';
import * as _ from 'lodash';
import { ActivatedRoute } from '@angular/router';
@Injectable({
  providedIn: 'root'
})
export class CommonService {
  private _endLocationRequest = new Subject<any>();
  private _unityDataSubscriber = new Subject<any>();
  private _resetMapRequest = new Subject<any>();
  private _locChangeRequest = new Subject<any>();
  venue!: Mappedin;
  selectedMapAllLoc: any;
  selectedBuildingAllLoc: any;
  startLoc: any = [];
  isPageRefreshd = true;
  unityData: any = {};
  private refreshInterval = 24 * 60 * 60 * 1000; // 24 hours in milliseconds
  // private refreshInterval = 10000;
  private lastMapUpdateTime: Date = new Date();
  public refreshSubscription!: Subscription;
  _locationDataOfAllBuilding: any;
  _locationDataOfSameFloor: any;
  brandsData: any = [];
  private _brandsDataOfAllBuilding: any;
  private _brandsDataOfSameFloor: any;
  includeAmenity = ['information-counter', 'food-court', 'offices'];
  constructor(
    private http: HttpClient,
    private route: ActivatedRoute
  ) {}
  private getLocationsURL = environment.mappedinApiGatewayUrl + 'public/1/location/dallas-market-center';
  private getMapsURL = environment.mappedinApiGatewayUrl + 'public/1/map/dallas-market-center';
  // private getIdentityTokenURL = environment.identityTokenGatewayUrl + 'connect/token';
  private getIdentityTokenURL = _.includes(environment.identityTokenGatewayUrl, 'devids')
    ? 'connect/token'
    : environment.identityTokenGatewayUrl + 'connect/token';
  private getUnityLocationsURL = _.includes(environment.unityApiGatewayUrl, 'devapi')
    ? 'Integration/MappedIn/Locations'
    : environment.unityApiGatewayUrl + 'Integration/MappedIn/Locations';
  private getUnityCategoriesURL = _.includes(environment.unityApiGatewayUrl, 'devapi')
    ? 'Integration/MappedIn/Categories'
    : environment.unityApiGatewayUrl + 'Integration/MappedIn/Categories';
  getBannerURL = environment.apiUrl + 'dmcmap/api/banner/getall';
  stackMapURL = environment.apiUrl + 'dmcmap/api/stackmap/details';
  getBrandsURL = environment.apiUrl + 'dmcmap/api/brandlist/getall';
  setEndLocationRequest(data: any) {
    this._resetMapRequest.next(data);
  }
  getEndLocationRequest(): Observable<any> {
    return this._resetMapRequest.asObservable();
  }
  setLocationChangeRequest(data: any) {
    this._locChangeRequest.next(data);
  }
  getLocationChangeRequest(): Observable<any> {
    return this._locChangeRequest.asObservable();
  }
  setResetMapEvent(data: any) {
    this._endLocationRequest.next(data);
  }
  getResetMapEvent(): Observable<any> {
    return this._endLocationRequest.asObservable();
  }
  getLocationList(fieldString: any) {
    const params = new HttpParams().append('field', fieldString);
    return this.http.get(this.getLocationsURL, { params: params });
  }
  getMapList(fieldString: any) {
    const params = new HttpParams().append('field', fieldString);
    return this.http.get(this.getMapsURL, { params: params });
  }
  public setVenuedetails(data: any) {
    this.venue = data;
  }
  public getVenuedetails() {
    return this.venue;
  }
  public setPageRefreshdFlag(data: boolean) {
    this.isPageRefreshd = data;
  }
  public getPageRefreshdFlag() {
    return this.isPageRefreshd;
  }
  public setSelectedMapAllLoc(data: any) {
    this.selectedMapAllLoc = data;
  }
  public getSelectedMapAllLoc() {
    return this.selectedMapAllLoc;
  }
  public setSelectedBuildingAllLoc(data: any) {
    this.selectedBuildingAllLoc = data;
  }
  public getSelectedBuildingAllLoc() {
    return this.selectedBuildingAllLoc;
  }

  public getIdentityToken() {
    const body = new URLSearchParams();
    body.set('client_secret', environment.dmcVenueOptions.unityClientSecret);
    body.set('client_id', environment.dmcVenueOptions.unityClientId);
    body.set('grant_type', environment.dmcVenueOptions.unityGrantType);
    body.set('scope', environment.dmcVenueOptions.unityScope);
    const headers = new HttpHeaders({
      'Content-Type': 'application/x-www-form-urlencoded'
    });
    return this.http.post(this.getIdentityTokenURL, body, { headers: headers });
  }
  public getUnityLocationsData() {
    const headers = new HttpHeaders({
      Authorization: 'Bearer ' + localStorage.getItem('_accessToken')
    });
    return this.http.get(this.getUnityLocationsURL, { headers: headers });
  }
  public getUnityCategoriesData() {
    const headers = new HttpHeaders({
      Authorization: 'Bearer ' + localStorage.getItem('_accessToken')
    });
    return this.http.get(this.getUnityCategoriesURL, { headers: headers });
  }
  public async setUnityApiData(): Promise<any> {
    this.unityData = {};
    const locationsObservable = this.getUnityLocationsData();
    const categoriesObservable = this.getUnityCategoriesData();
    const combinedObservables = forkJoin([locationsObservable, categoriesObservable]);
    try {
      const results = await lastValueFrom(combinedObservables);
      this.unityData['_unityLocationsData'] = results[0];
      this.unityData['_unityCategoriesData'] = results[1];
      await this.fillCategoryNames(results[0], results[1]).then(async (res: any) => {
        this.unityData['_unityLocationsCategoriesData'] = res;

        // Retrieve data from local storage
        // const locationDataOfSameFloor = JSON.parse(localStorage.getItem('_locationDataOfSameFloor') as string);
        // const locationDataOfAllBuilding = JSON.parse(localStorage.getItem('_locationDataOfAllBuilding') as string);
        const locationDataOfSameFloor = JSON.parse(this.getSameFloorLoc());
        const locationDataOfAllBuilding = JSON.parse(this.getAllBuildingLoc());

        const unityLocationsCategoriesData = this.unityData._unityLocationsCategoriesData;

        // Update categories in _locationDataOfSameFloor
        await this.updateCategories(locationDataOfSameFloor, unityLocationsCategoriesData);

        // Update categories in _locationDataOfAllBuilding
        await this.updateCategories(locationDataOfAllBuilding, unityLocationsCategoriesData);

        // Store the updated data back in local storage
        // localStorage.setItem('_locationDataOfSameFloor', _.cloneDeep(JSON.stringify(locationDataOfSameFloor)));
        // localStorage.setItem('_locationDataOfAllBuilding', _.cloneDeep(JSON.stringify(locationDataOfAllBuilding)));
        this._locationDataOfSameFloor = _.cloneDeep(JSON.stringify(locationDataOfSameFloor));
        this._locationDataOfAllBuilding = _.cloneDeep(JSON.stringify(locationDataOfAllBuilding));
        this._unityDataSubscriber.next({
          unityData: this.unityData,
          locationDataOfSameFloor: locationDataOfSameFloor,
          locationDataOfAllBuilding: locationDataOfAllBuilding
        });
      });
      return results;
    } catch (error) {
      console.error('Error:', error);
      throw error; // Rethrow the error for handling at the caller level
    }
  }
  public getUnityApiData(): Observable<any> {
    return this._unityDataSubscriber.asObservable();
  }
  fillCategoryNames(locations: any, categories: any): Promise<any> {
    return new Promise(resolve => {
      _.forEach(locations, location => {
        location.categoryNames = _.map(location.categories, categoryId => {
          const matchingCategory = _.find(categories, { externalId: categoryId });
          return matchingCategory ? matchingCategory.name : 'Unknown Category';
        });
      });
      resolve(locations);
    });
  }
  async updateCategories(locations: any[], unityLocationsCategoriesData: any[]) {
    for (const item of locations) {
      await this.updateCategoriesByExternalId(item, item.externalId, unityLocationsCategoriesData);
    }
  }
  async updateCategoriesByExternalId(location: any, externalId: string, unityLocationsCategoriesData: any) {
    await _.forEach(unityLocationsCategoriesData, res => {
      if (res.externalId === Number(externalId)) {
        location['categories'] = res['categories'];
        location['categoryNames'] = _.sortBy(res['categoryNames']);
        location['logoKey'] = res['logoKey'];
      }
    });
  }
  public async setStorageLocDataOfSameFloor(selectedMapAllLocation: any) {
    if (_.isEmpty(this.unityData)) {
      // localStorage.setItem('_locationDataOfSameFloor', JSON.stringify(_.sortBy(selectedMapAllLocation, 'name')));
      this._locationDataOfSameFloor = JSON.stringify(_.sortBy(_.cloneDeep(selectedMapAllLocation), 'name'));
      this._brandsDataOfSameFloor = JSON.stringify(
        _.orderBy(await this.processLocationData(_.cloneDeep(selectedMapAllLocation), true), ['name', 'brandName'])
      );
      // console.log('>>>1', this._brandsDataOfSameFloor);
    } else {
      // Retrieve data from local storage
      const unityLocationsCategoriesData = this.unityData._unityLocationsCategoriesData;
      // Update categories in _locationDataOfSameFloor
      await this.updateCategories(selectedMapAllLocation, unityLocationsCategoriesData);
      // Store the updated data back in local storage
      // localStorage.setItem('_locationDataOfSameFloor', _.cloneDeep(JSON.stringify(selectedMapAllLocation)));
      this._locationDataOfSameFloor = _.cloneDeep(JSON.stringify(selectedMapAllLocation));
    }
  }
  public async setStorageLocDataOfAllBuilding(filterAllBuildingAllLoc: any) {
    if (_.isEmpty(this.unityData)) {
      // localStorage.setItem('_locationDataOfAllBuilding', JSON.stringify(_.sortBy(filterAllBuildingAllLoc, 'name')));
      this._locationDataOfAllBuilding = JSON.stringify(_.sortBy(_.cloneDeep(filterAllBuildingAllLoc), 'name'));
      console.log('???filterAllBuildingAllLoc:', filterAllBuildingAllLoc, this.brandsData);
      this._brandsDataOfAllBuilding = JSON.stringify(
        _.orderBy(await this.processLocationData(_.cloneDeep(filterAllBuildingAllLoc), true), ['name', 'brandName'])
      );
      // console.log('>>>2', this._brandsDataOfAllBuilding);
    } else {
      // Retrieve data from local storage
      const unityLocationsCategoriesData = this.unityData._unityLocationsCategoriesData;
      // Update categories in _locationDataOfAllBuilding
      await this.updateCategories(filterAllBuildingAllLoc, unityLocationsCategoriesData);
      // Store the updated data back in local storage
      // localStorage.setItem('_locationDataOfAllBuilding', _.cloneDeep(JSON.stringify(filterAllBuildingAllLoc)));
      this._locationDataOfAllBuilding = _.cloneDeep(JSON.stringify(filterAllBuildingAllLoc));
    }
  }
  public async processLocationData(locationData: any, isBrandsPage: boolean): Promise<any> {
    return new Promise(resolve => {
      // const uniqueLocations = _.uniqBy(_.cloneDeep(locationData), 'id');
      const uniqueLocations = _.cloneDeep(locationData);
      const modifiedData = _(uniqueLocations)
        .flatMap((location: any) => {
          if (isBrandsPage) {
            if (!location.brands || location.brands.length === 0) {
              // If no brands, include the current location itself
              const modifiedLocation = {
                name: location.name,
                spaceExternalD: location.spaceExternalD,
                id: location.id,
                mapElevation: location.mapElevation,
                mapGroupCode: location.mapGroupCode,
                mapGroupId: location.mapGroupId,
                mapGroupName: location.mapGroupName,
                mapId: location.mapId,
                brandName: null,
                type: location.type,
                tags: location.tags
              };
              return modifiedLocation;
            } else {
              return _.map(location.brands, brand => {
                // const modifiedLocation = { ...location, brandName: brand };
                const modifiedLocation = {
                  name: location.name,
                  // spaceExternalD: location.spaceExternalD,
                  spaceExternalD: [brand.polygonId],
                  id: location.id,
                  mapElevation: location.mapElevation,
                  mapGroupCode: location.mapGroupCode,
                  mapGroupId: location.mapGroupId,
                  mapGroupName: location.mapGroupName,
                  mapId: location.mapId,
                  brandName: brand.name,
                  type: location.type,
                  tags: location.tags
                };
                return modifiedLocation;
                // return this.modifyLocation(modifiedLocation);
              });
            }
          } else {
            return this.modifyLocation(location);
          }
        })
        .compact()
        .value();
      resolve(modifiedData);
    });
  }

  private modifyLocation(location: any): any {
    if (
      location.type === 'tenant' ||
      (location.type === 'amenities' && _.includes(this.includeAmenity, location.amenity))
    ) {
      if (location.description) {
        location.description = location.description.substring(0, 150) + '...';
      }
      return location;
    }
    return null;
  }

  public getAllBuildingLoc() {
    return this._locationDataOfAllBuilding;
  }
  public getSameFloorLoc() {
    return this._locationDataOfSameFloor;
  }
  public getAllBuildingBrands() {
    return this._brandsDataOfAllBuilding;
  }
  public getSameFloorBrands() {
    return this._brandsDataOfSameFloor;
  }
  startAutoRefresh() {
    // Use Angular's interval to trigger data refresh every 24 hours
    this.refreshSubscription = interval(this.refreshInterval).subscribe(() => {
      this.refreshData();
    });
  }
  stopAutoRefresh() {
    // Stop the refresh interval when needed
    this.refreshSubscription.unsubscribe();
  }
  private refreshData() {
    // Make a request to your Unity API to check for updates
    // this.http.get('https://unity-api-url/check-for-updates').subscribe((response) => {
    //   console.log('Data refreshed:', response);
    // });
    const sampleDataSyncResponse = {
      status: 'success',
      message: 'Updates available',
      data: {
        maps: [
          {
            id: '61ae719b579191eb1787db2d',
            name: "7 - Men's Apparel, Accessories and Footwear, Fine Jewelry, Cash & Carry",
            updated_at: '2023-11-08T12:30:00Z'
          },
          {
            id: '61ae7618366f3c36c25228d9',
            name: "8 - Children's Apparel, Accessories, Gift, Toy",
            updated_at: '2023-11-09T12:00:00Z'
          }
        ]
      }
    };
    if (sampleDataSyncResponse.status === 'success') {
      const updatedMaps = sampleDataSyncResponse.data.maps;
      if (this.shouldReloadPage(updatedMaps)) {
        // Reload the page or trigger relevant actions
        location.reload();
      } else {
        // Update the lastMapUpdateTime if needed
        this.updateLastMapUpdateTime(updatedMaps);
      }
    }
  }
  private updateLastMapUpdateTime(updatedMaps: any[]): void {
    // Update the lastMapUpdateTime with the latest updated_at timestamp for maps
    const latestMapUpdateTime = this.getSelectedMapUpdateTime(updatedMaps);
    this.lastMapUpdateTime = latestMapUpdateTime;
  }
  private shouldReloadPage(updatedMaps: any[]): boolean {
    // Compare the lastMapUpdateTime with the latest updated_at timestamp for maps
    const latestMapUpdateTime = this.getSelectedMapUpdateTime(updatedMaps);
    return latestMapUpdateTime > this.lastMapUpdateTime;
  }
  private getSelectedMapUpdateTime(updatedMaps: any[]): Date {
    // Find the maximum updated_at timestamp among the updated maps
    const selectedMapId = this.route.root.firstChild?.snapshot.paramMap.get('mapId');
    const mapTimestamps: any = _.find(updatedMaps, map => map.id === selectedMapId);
    return new Date(mapTimestamps.updated_at);
  }

  public async getAllBanner(): Promise<any> {
    return new Promise((resolve, reject) => {
      this.http.get(this.getBannerURL).subscribe({
        next: response => {
          resolve(response);
        },
        error: error => {
          reject(error.error);
        }
      });
    });
  }

  public getCampusData() {
    const params = new HttpParams().append('sortBy', 'desc');
    return this.http.get(this.stackMapURL, { params: params });
  }

  async prepareSameFloorLocData(currentMap: any): Promise<any[]> {
    // console.log('prepareSameFloorLocData', currentMap, _.cloneDeep(this.getBrandsData()));
    let selectedMapAllLocation: any = [];
    const uniqueCombinations = new Set();

    if (currentMap && currentMap.locations?.length) {
      // const uniqueLocations = _.uniqBy(currentMap.locations, 'id');
      const uniqueLocations = currentMap.locations;
      selectedMapAllLocation = uniqueLocations
        .map(
          ({
            id,
            name,
            description,
            detailsUrl,
            logo,
            externalId,
            operationHours,
            phone,
            type,
            tags,
            categories,
            states,
            metadata,
            picture,
            polygons,
            amenity
          }: any) => {
            // Extract spaceKey from all polygons

            const spaceKeys =
              polygons
                ?.map((polygon: any) => {
                  // Check if polygon.externalId is available
                  if (
                    polygon.externalId &&
                    !polygon.externalId.startsWith('POLY') &&
                    polygon.map.id === currentMap.id
                  ) {
                    return `${polygon.externalId}`;
                  } else {
                    return null; // or any default value or an empty string based on your requirement
                  }
                })
                .filter(Boolean) || [];
            const combination = `${id}-${currentMap.id}`;
            if (!uniqueCombinations.has(combination)) {
              uniqueCombinations.add(combination);
              return {
                id,
                name,
                description,
                detailsUrl,
                logo,
                externalId,
                operationHours,
                phone,
                type,
                tags,
                categories,
                states,
                metadata,
                amenity,
                combinationId: combination,
                brands: _.chain(this.getBrandsData())
                  .filter(
                    res =>
                      res.exhibitorId === Number(externalId) &&
                      _.some(res.polygons, polygon => _.includes(spaceKeys, polygon))
                  )
                  .orderBy(['name'], ['asc']) // Change 'asc' to 'desc' for descending order
                  .flatMap(brand => _.map(brand.polygons, polygon => ({ name: brand.name, polygonId: polygon })))
                  .value(),
                picture: picture?.original,
                mapElevation: currentMap.elevation,
                mapId: currentMap.id,
                mapGroupId: currentMap.group,
                mapGroupName: currentMap.mapGroup?.name,
                mapGroupCode: currentMap.mapGroup?.shortName,
                spaceExternalD: spaceKeys
              };
            }
            return null;
          }
        )
        .filter((location: any) => location !== null);
    }

    this.setStorageLocDataOfSameFloor(selectedMapAllLocation);
    // console.log('selectedMapAllLocation::', selectedMapAllLocation);
    // Return the data that the component needs
    return _.orderBy(selectedMapAllLocation, ['name']);
  }

  async prepareAllBuildingLocData(venue: any): Promise<any[]> {
    console.log('prepareAllBuildingLocData', venue);
    const sameBuildingAllLoc = _.flatMap(venue.maps, 'locations');
    const uniqueCombinations = new Set();
    const filterAllBuildingAllLoc = _.flatMap(sameBuildingAllLoc, (location: any) => {
      const matchingMaps = _.filter(venue.maps, (map: any) => _.some(map.locations, { id: location.id }));
      return _.map(matchingMaps, (mapData: any) => {
        // Extract spaceKey from all polygons
        const spaceKeys =
          location.polygons
            ?.map((polygon: any) => {
              // Check if polygon.externalId is available
              if (polygon.externalId && !polygon.externalId.startsWith('POLY') && polygon.map.id === mapData.id) {
                return `${polygon.externalId}`;
              } else {
                return null; // or any default value or an empty string based on your requirement
              }
            })
            .filter(Boolean) || [];
        const combination = `${location.id}-${mapData.id}`;
        if (!uniqueCombinations.has(combination)) {
          uniqueCombinations.add(combination);
          return {
            ...location,
            brands: _.chain(this.getBrandsData())
              .filter(
                res =>
                  res.exhibitorId === Number(location.externalId) &&
                  _.some(res.polygons, polygon => _.includes(spaceKeys, polygon))
              )
              .orderBy(['name'], ['asc']) // Change 'asc' to 'desc' for descending order
              .flatMap(brand => _.map(brand.polygons, polygon => ({ name: brand.name, polygonId: polygon })))
              .value(),
            combinationId: combination,
            picture: location?.picture?.original,
            mapElevation: mapData.elevation,
            mapId: mapData.id,
            mapGroupId: mapData.group,
            mapGroupName: mapData.mapGroup?.name,
            mapGroupCode: mapData.mapGroup.shortName,
            spaceExternalD: spaceKeys
          };
        }
        return null;
      });
    });
    const filteredAllBuildingAllLoc = _.compact(filterAllBuildingAllLoc);
    this.setStorageLocDataOfAllBuilding(filteredAllBuildingAllLoc);
    // console.log('filteredAllBuildingAllLoc::', filteredAllBuildingAllLoc);
    // Return the data that the component needs
    return _.orderBy(filteredAllBuildingAllLoc, ['name']);
  }
  public setStartLocData(allPolygons: any) {
    this.startLoc = _.map(
      allPolygons.filter((polygon: any) => polygon.externalId && polygon.externalId.startsWith('MAP-')),
      (polygon: any) => {
        polygon.mapId = polygon.map.id;
        polygon.mapGroupId = polygon.map.mapGroup.id;
        return polygon;
      }
    );
  }
  public getStartLocData() {
    return this.startLoc;
  }
  // public loadBrandsData() {
  //   const headers = new HttpHeaders({});
  //   return this.http.get(this.getBrandsURL, { headers: headers });
  // }
  public async loadBrandsData(): Promise<any> {
    return new Promise((resolve, reject) => {
      this.http.get(this.getBrandsURL).subscribe({
        next: response => {
          resolve(response);
        },
        error: error => {
          reject(error.error);
        }
      });
    });
  }
  public storeBrandsData(data: any) {
    this.brandsData = data;
  }
  public getBrandsData() {
    return this.brandsData;
  }
  public matchLocationDescription(data: any, word: any) {
    let locationName = data.name;
    if (data.name && data.name.toLowerCase() === word) {
      const wordsToExtract = data.name.split('/');
      const matchedWords = _.filter(wordsToExtract, (word: any) => _.includes(data.description, word));
      if (matchedWords && matchedWords.length === 1) {
        locationName = matchedWords[0];
      }
    }
    return locationName;
  }
}
