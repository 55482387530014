import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {
    path: '',
    redirectTo: '/maps',
    pathMatch: 'full'
  },
  {
    path: 'maps',
    loadComponent: () => import('./components/pages/all-floors/all-floors.component').then(m => m.AllFloorsComponent),
    data: { mappage: true, pageTitle: 'Home', checkResetTimer: false }
  },
  {
    // Define the route for the root path
    path: 'maps/:mapId',
    // Lazy load the MappedinComponent
    loadComponent: () =>
      import('./components/pages/mapped-home/mapped-home.component').then(m => m.MappedHomeComponent),
    data: { mappage: true, pageTitle: 'Map', checkResetTimer: false }
  },
  {
    path: 'maps/:mapId/directory',
    loadComponent: () =>
      import('./components/pages/mapped-directory/mapped-directory.component').then(m => m.MappedDirectoryComponent),
    data: { pageTitle: 'Directory', checkResetTimer: true }
  },
  {
    path: 'maps/:mapId/showroom',
    loadComponent: () =>
      import('./components/pages/mapped-directory-details/mapped-directory-details.component').then(
        m => m.MappedDirectoryDetailsComponent
      ),
    data: { showroom: true, pageTitle: 'Showroom', checkResetTimer: true }
  },
  {
    path: 'maps/:mapId/event',
    loadComponent: () =>
      import('./components/pages/mapped-directory-details/mapped-directory-details.component').then(
        m => m.MappedDirectoryDetailsComponent
      ),
    data: { event: true, pageTitle: 'Event', checkResetTimer: true }
  },
  {
    path: 'maps/:mapId/all-floors',
    loadComponent: () => import('./components/pages/all-floors/all-floors.component').then(m => m.AllFloorsComponent),
    data: { pageTitle: 'All Floors', checkResetTimer: true }
  },
  {
    path: 'maps/:mapId/all-brands',
    loadComponent: () =>
      import('./components/pages/mapped-directory/mapped-directory.component').then(m => m.MappedDirectoryComponent),
    data: { brandsPage: true, pageTitle: 'All Brands', checkResetTimer: true }
  },
  {
    // Catch all other routes and redirect to the root path
    path: '**',
    redirectTo: '/',
    pathMatch: 'full'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {}
