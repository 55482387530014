import { Component, HostListener, Injector, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, GuardsCheckStart, NavigationEnd, Router } from '@angular/router';
import { Subscription, filter } from 'rxjs';
import { environment } from '../environments/environment';
import { CommonService } from './services/common.service';
import { GoogleTagManagerService } from 'angular-google-tag-manager';
import { Title } from '@angular/platform-browser';
import { Stomp } from '@stomp/stompjs';
import * as SockJS from 'sockjs-client';
import { UserIdleService } from 'angular-user-idle';
import * as _ from 'lodash';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {
  locationFromId: any;
  locationToId: any;
  mapId: any;
  env = environment;
  //   today: number = Date.now();
  gtmService: any;
  public subscription: Subscription;
  private stompClient: any;
  public subscriptionTimerStart: Subscription | any;
  public subscriptionTimerOut: Subscription | any;
  public resetMapsubscription: Subscription | any;
  public LocationChangeSubscription: Subscription | any;
  resetMapModalVisible = false;
  resetCounter = 30;
  resetFormattedCounter = '00:15';
  isEmptyQueryParams: any;
  polygonClick = false;
  checkResetTimer: any;
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private commonService: CommonService,
    private injector: Injector,
    private pageTitle: Title,
    private userIdle: UserIdleService
  ) {
    if (environment.googleTagManagerID !== '') {
      this.gtmService = this.injector.get<any>(GoogleTagManagerService);
    }
    // this.clientConnect();
    this.subscription = this.router.events
      .pipe(filter((rs: any): rs is NavigationEnd => rs instanceof NavigationEnd))
      .subscribe((event: any) => {
        this.mapId = this.route.root.firstChild?.snapshot.paramMap.get('mapId');
        this.locationFromId = this.route.root.firstChild?.snapshot.paramMap.get('locationFromId');
        this.isEmptyQueryParams = _.isEmpty(this.route.snapshot.queryParams);
        this.checkResetTimer = this.route.root.firstChild?.snapshot.data['checkResetTimer'];
        console.log('On NavigationEnd:', !this.isEmptyQueryParams, this.checkResetTimer);
        if (!this.isEmptyQueryParams || this.checkResetTimer) {
          this.restartIdleTracking();
        } else {
          this.userIdle.stopWatching();
          this.userIdle.stopTimer();
          this.userIdle.resetTimer();
        }
        if (environment.googleTagManagerID !== '') {
          setTimeout(() => {
            const gtmTag = {
              event: 'page',
              pageName: event.url
            };
            this.gtmService.pushTag(gtmTag);
            /* if you want to recive tags without pushing events simply call the function to enable it */
            // this.gtmService.addGtmToDom();
          }, 50);
        }
        if (event.id === 1 && event.url === event.urlAfterRedirects) {
          // Your code here for when the page is refreshd
          this.commonService.setPageRefreshdFlag(true);
        } else {
          this.commonService.setPageRefreshdFlag(false);
        }
      });
    this.router.events.subscribe(event => {
      if (event instanceof GuardsCheckStart) {
        let title = 'DMC People Navigation';
        if (
          event &&
          event.state &&
          event.state.root &&
          event.state.root.firstChild &&
          event.state.root.firstChild.data
        ) {
          title += ' | ' + event.state.root.firstChild.data['pageTitle'];
          this.emitPageEvent(event.state.root.firstChild.data['pageTitle']);
        }
        this.pageTitle.setTitle(title);
      }
    });
    this.LocationChangeSubscription = this.commonService.getLocationChangeRequest().subscribe(async res => {
      console.log('res:', res);
      this.polygonClick = true;
      this.restartIdleTracking();
    });
    this.resetMapsubscription = this.commonService.getResetMapEvent().subscribe(async () => {
      const isInMapPage = this.route.root.firstChild?.snapshot?.data?.['mappage'];
      if (isInMapPage) {
        console.log('Map Reset On Same Page:', isInMapPage);
        this.isEmptyQueryParams = true;
        if (!this.isEmptyQueryParams) {
          this.restartIdleTracking();
        } else {
          this.userIdle.stopWatching();
          this.userIdle.stopTimer();
          this.userIdle.resetTimer();
        }
      }
    });
    // Subscribe to UserIdleService events
    this.subscriptionTimerStart = this.userIdle.onTimerStart().subscribe((count: number) => {
      console.log('User idle timer started. Count:', count, this.resetCounter);
      if (count) {
        this.resetCounter = 16 - count; // Reverse the countdown
        this.resetFormattedCounter = this.getFormattedTime(_.cloneDeep(this.resetCounter));
        this.resetMapModalVisible = true;
        if (this.resetCounter <= -1 && this.resetMapModalVisible) {
          this.commonService.setResetMapEvent(true);
          this.restartIdleTracking();
        }
      }
    });

    this.subscriptionTimerOut = this.userIdle.onTimeout().subscribe((count: any) => {
      console.log('User idle timeout occurred. Resetting map.', count);
      this.router
        .navigate(['/maps', this.mapId], {
          relativeTo: this.route,
          queryParams: { to: this.locationToId }
        })
        .then(() => {
          this.resetCounter = 30;
          this.resetMapModalVisible = false;
          this.polygonClick = false;
          this.commonService.setResetMapEvent(true);
        });
    });
  }
  @HostListener('document:click', ['$event'])
  @HostListener('document:touchstart', ['$event'])
  @HostListener('document:keypress', ['$event'])
  @HostListener('window:scroll', ['$event'])
  handleUserInteraction(event: Event) {
    // Check the event type and call emitPageEvent only for clicks and keypresses
    if (event instanceof PointerEvent || event instanceof KeyboardEvent) {
      this.emitPageEvent('User Interaction');
    }
  }
  emitPageEvent(pageTitle: any) {
    if (pageTitle !== 'User Interaction') {
      const dataObj = {
        isStackMapPage: pageTitle === 'Home' ? true : false,
        pageTitle
      };
      if (dataObj) {
        window.parent.postMessage({ ...dataObj }, '*');
      }
    }
  }
  onUserActivity() {
    // When user interacts with the page, restart idle tracking
    console.log('onUserActivity:', !this.isEmptyQueryParams, this.polygonClick, this.checkResetTimer);
    if (!this.isEmptyQueryParams || this.polygonClick || this.checkResetTimer) {
      this.restartIdleTracking();
    } else {
      this.userIdle.stopWatching();
      this.userIdle.stopTimer();
      this.userIdle.resetTimer();
    }
  }
  restartIdleTracking() {
    this.userIdle.stopWatching();
    this.userIdle.stopTimer();
    this.userIdle.resetTimer();
    this.userIdle.startWatching();
    this.resetCounter = 30;
    this.resetMapModalVisible = false;
  }
  getFormattedTime(count: any): string {
    // Assuming count is in seconds
    const minutes = Math.floor(count / 60);
    const seconds = count % 60;
    // Use lodash padStart to add leading zeros
    const formattedMinutes = _.padStart(minutes.toString(), 2, '0');
    const formattedSeconds = _.padStart(seconds.toString(), 2, '0');
    return `${formattedMinutes}:${formattedSeconds}`;
  }
  clientConnect() {
    const options = {
      transports: ['websocket', 'xhr-polling'] // Specify allowed transports
    };
    this.stompClient = Stomp.over(new SockJS(`${environment.apiUrl}notificationservice`, null, options));
    // Configure heartbeats (values in milliseconds)
    // this.stompClient.heartbeatIncoming = 4000; // Client will send heartbeats every 20000ms
    // this.stompClient.heartbeatOutgoing = 4000; // Client expects to receive heartbeats at least every 20000ms
    this.stompClient.connect(
      {},
      (frame: any) => {
        console.log('Connected to WebSocket', frame);
        // Subscribe to any necessary topics
        this.stompClient.subscribe('/allmap/notification', (message: any) => {
          const notificationMsg = _.cloneDeep(JSON.parse(message.body));
          console.log('Received message:', notificationMsg, this.mapId);
          if (notificationMsg && notificationMsg.reboot && this.mapId === notificationMsg.mapID) {
            location.reload();
          }
          // Handle received message
        });
        // this.stompClient.publish({ destination: '/allmap/notification', body: 'First Message' });
      },
      (error: any) => {
        console.error('Error connecting to WebSocket:', error);
        this.clientConnect();
      },
      (closeEvent: any) => {
        console.error('WebSocket Closed:', closeEvent);
        this.stompClient.deactivate();
        setTimeout(() => {
          this.clientConnect();
        }, 5000); // Reconnect after 5 seconds
      }
    );
    this.stompClient.deactivate();
    this.stompClient.activate();
  }
  ngOnInit() {
    this.mapId = this.route.snapshot.paramMap.get('mapId');
    this.locationFromId = this.route.snapshot.paramMap.get('locationFromId');
    // Start the auto-refresh mechanism when the component initializes
    this.commonService.startAutoRefresh();
    // setInterval(() => {
    //   this.today = Date.now();
    // }, 1000);
  }
  ngOnDestroy() {
    if (this.subscription !== undefined) {
      this.subscription.unsubscribe();
    }
    if (this.stompClient) {
      this.stompClient.deactivate();
    }
    // Stop the auto-refresh mechanism when the component is destroyed or as needed
    this.commonService.stopAutoRefresh();

    this.resetMapsubscription.unsubscribe();
    if (this.subscriptionTimerStart) {
      this.subscriptionTimerStart.unsubscribe();
    }
    if (this.subscriptionTimerOut) {
      this.subscriptionTimerOut.unsubscribe();
    }
  }
  stayButton() {
    this.restartIdleTracking();
    this.resetMapModalVisible = false;
  }
}
