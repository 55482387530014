export const environment = {
  domainExt: window.location.host.split('.').pop(),
  apiUrl: 'https://qaapi.maptv.dallasmarketcenter.app/',
  mappedinApiGatewayUrl: 'https://api-gateway.mappedin.com/',
  identityTokenGatewayUrl: 'https://testids.unityedge.com/',
  unityApiGatewayUrl: 'https://testapi.unityedge.com/',
  bucketUrl: 'https://dmc-mappedin-qa-asset.s3.amazonaws.com/',
  googleTagManagerID: '',
  production: true,
  showError: true,
  // See Trial API key Terms and Conditions https://developer.mappedin.com/api-keys/
  demoVenueOptions: {
    venue: 'mappedin-demo-mall',
    clientId: '5eab30aa91b055001a68e996',
    clientSecret: 'RJyRXKcryCMy4erZqqCbuB1NbR66QTGNXVE0x3Pg6oCIlUR1'
  },
  dmcVenueOptions: {
    venue: 'dallas-market-center',
    clientId: '64db9b949e7924001cd2624e',
    clientSecret: 'Zyzx4qEJfZzvahU4Y7S92XE49FUbHfTTYQNZY0gM4CVgv7hD',
    unityClientId: 'mappedin',
    unityClientSecret: '71nmjurezS5QPajGrKcNfe6g4pACZ5RnOaRAD6gWRzo=',
    unityGrantType: 'client_credentials',
    unityScope: 'mappedin',
    XMappedinSecret: 'Wnl6eDRxRUpmWnp2YWhVNFk3UzkyWEU0OUZVYkhmVFRZUU5aWTBnTTRDVmd2N2hE',
    XMappedinKey: 'NjRkYjliOTQ5ZTc5MjQwMDFjZDI2MjRl',
    showAllFloorLocation: false,
    emitAnalyticsEvents: true
  }
};
