<div class="mappedin-ad-part">
  <ng-container *ngIf="isVideoBanner">
    <!-- Load video -->
    <video #adVideo controls muted autoplay loop controlsList="nodownload" (loadedmetadata)="hideControls(adVideo)">
      <source [src]="getBucketDomain + bannerPath" type="video/mp4" />
      Your browser does not support the video tag.
    </video>
  </ng-container>
  <ng-container *ngIf="!isVideoBanner">
    <!-- Load image -->
    <img
      #adimg
      src="{{ getBucketDomain + bannerPath }}"
      (error)="adimg.src = getBucketDomain + 'dmc_mappdin_default_banner.png'"
      alt="Banner ads" />
  </ng-container>
</div>
