import { catchError } from 'rxjs/operators';
import { Injectable, Injector } from '@angular/core';
import { HttpInterceptor, HttpHandler, HttpEvent, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import * as _ from 'lodash';
import { environment } from '../../environments/environment';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  /*mappedinApiBaseUrl = environment.mappedinApiGatewayUrl;
    identityTokenBaseURL = environment.identityTokenGatewayUrl;
    unityApiBaseUrl = environment.unityApiGatewayUrl;*/
  static addToken(req: HttpRequest<any>, token: string): HttpRequest<any> {
    let header: any = {};
    if (_.includes(req.url, environment.mappedinApiGatewayUrl)) {
      header = {
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Methods': 'GET, POST, OPTIONS, PUT, PATCH, DELETE',
        'Access-Control-Allow-Headers': 'X-Requested-With,content-type',
        'Access-Control-Allow-Credentials': 'true',
        'X-Mappedin-Secret': environment.dmcVenueOptions.XMappedinSecret,
        'X-Mappedin-Key': environment.dmcVenueOptions.XMappedinKey
      };
    }
    if (_.includes(req.url, environment.identityTokenGatewayUrl)) {
      header = {
        'Content-Type': 'application/x-www-form-urlencoded'
      };
    }
    if (_.includes(req.url, environment.unityApiGatewayUrl)) {
      header['Authorization'] = 'Bearer ' + token;
    }
    if (_.includes(req.url, environment.apiUrl)) {
      header = {
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Methods': 'GET, POST, PUT, PATCH, DELETE',
        'Access-Control-Allow-Headers': 'X-Requested-With,content-type',
        'Access-Control-Allow-Credentials': 'true'
      };
    }
    return req.clone({ setHeaders: header });
  }
  constructor(private injector: Injector) {}
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const token: any = localStorage.getItem('_accessToken');
    return next.handle(AuthInterceptor.addToken(req, token)).pipe(
      catchError((error: any): Observable<any> => {
        throw error;
      })
    );
  }
}
