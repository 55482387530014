import { Component, OnInit } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { ActivatedRoute } from '@angular/router';
import * as _ from 'lodash';

@Component({
  selector: 'app-mappedin-ad',

  templateUrl: './mappedin-ad.component.html',
  styleUrls: ['./mappedin-ad.component.scss']
})
export class MappedinAdComponent implements OnInit {
  getBucketDomain = environment.bucketUrl;
  bannerPath: any = 'dmc_mappdin_default_banner.png';
  mapId: any;
  isVideoBanner: any = false;
  constructor(private route: ActivatedRoute) {}

  ngOnInit() {
    this.mapId = this.route.root.firstChild?.snapshot.paramMap.get('mapId');
    let retselectedLocationData: any = localStorage.getItem('_bannerData');
    if (retselectedLocationData) {
      retselectedLocationData = JSON.parse(retselectedLocationData);
      const bannerObj = _.find(retselectedLocationData, (res: any) => res.floorID === this.mapId);
      if (bannerObj) {
        this.bannerPath = bannerObj.bannerImageName;
        this.isVideoBanner = this.isVideo(this.bannerPath);
      } else {
        this.bannerPath = 'dmc_mappdin_default_banner.png';
        this.isVideoBanner = false;
      }
    }
  }
  private isVideo(filePath: string): boolean {
    return filePath.toLowerCase().endsWith('.mp4') || filePath.toLowerCase().endsWith('.webm');
  }
  hideControls(video: any) {
    // Set controls to false to hide the video controls
    video.controls = false;
    video.autoplay = true;
    video.muted = true;
    video.loop = true;

    // Wait for the video to load before attempting autoplay
    video.addEventListener('canplaythrough', () => {
      video.play();
    });
  }
}
