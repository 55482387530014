import { Injectable } from '@angular/core';
import { getVenue } from '@mappedin/mappedin-js';
import * as _ from 'lodash';
import { CommonService } from './common.service';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AppInitializerService {
  constructor(private commonService: CommonService) {}

  timeout(ms: number, promise: Promise<any>) {
    return new Promise((resolve, reject) => {
      const timeoutId = setTimeout(() => {
        clearTimeout(timeoutId);
        const spinner = document.getElementById('init-spinner');
        if (spinner) {
          spinner.classList.add('timeout-occurred');
        }
        // You can handle timeout occurrence here
        // For example, show a message to the user
        console.log('Timeout occurred');
      }, ms);
      promise.then(
        result => {
          clearTimeout(timeoutId);
          resolve(result);
          this.commonService.setStartLocData(result.polygons);
        },
        error => {
          clearTimeout(timeoutId);
          const spinner = document.getElementById('init-spinner');
          if (spinner) {
            spinner.classList.add('timeout-occurred');
          }
          reject(error);
        }
      );
    });
  }
  async initializeApp() {
    const [venue, bannerDataRes, loadBrandsRes] = await Promise.all([
      this.timeout(20000, getVenue(environment.dmcVenueOptions)),
      this.commonService.getAllBanner(),
      this.commonService.loadBrandsData()
    ]);

    // Process venue data
    this.commonService.setVenuedetails(venue);

    // Process banner data
    let bannerData: any = [];
    if (bannerDataRes && bannerDataRes.success) {
      bannerData = _.chain(bannerDataRes.data)
        .filter({ recordDeleted: false })
        .map(({ floorID, bannerImageName }) => ({ floorID, bannerImageName }))
        .value();
    }
    localStorage.setItem('_bannerData', JSON.stringify(bannerData));

    // Process loadBrands data
    this.commonService.storeBrandsData([]);
    if (loadBrandsRes && loadBrandsRes.success) {
      this.commonService.storeBrandsData(loadBrandsRes.data);
    }
  }
}
