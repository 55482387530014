import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

if (environment.production) {
  enableProdMode();
  if (window) {
    window.console.log = noop;
    window.console.warn = noop;
    window.console.info = noop;
    window.console.group = noop;
    if (!environment.showError) {
      window.console.error = noop;
    }
  }
}
// Define a "noop" (no operation) function
function noop() {
  // This function does nothing
}
platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch(err => console.error(err));
