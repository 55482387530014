import { AfterViewInit, ChangeDetectorRef, Component, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import * as _ from 'lodash';
import { MessageService } from 'primeng/api';
// import { AutoComplete } from 'primeng/autocomplete';
import { CommonService } from '../../../services/common.service';
import Keyboard from 'simple-keyboard';
import { AngularDraggableModule } from 'angular2-draggable';
import { ToastModule } from 'primeng/toast';
import { CommonModule } from '@angular/common';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { VirtualScroller, VirtualScrollerModule } from 'primeng/virtualscroller';
import { SplitterModule } from 'primeng/splitter';
import { InputTextModule } from 'primeng/inputtext';

@Component({
  selector: 'app-mappedin-search',
  templateUrl: './mappedin-search.component.html',
  styleUrls: ['./mappedin-search.component.scss'],
  standalone: true,
  imports: [
    AngularDraggableModule,
    ToastModule,
    CommonModule,
    AutoCompleteModule,
    FormsModule,
    ReactiveFormsModule,
    ProgressSpinnerModule,
    VirtualScrollerModule,
    SplitterModule
  ]
})
export class MappedinSearchComponent implements AfterViewInit {
  items: any[] | undefined;
  suggestionLocations: any = [];
  suggestionBrands: any = [];
  isOpenMenu = true;
  isOpenSearch = false;
  allLocations: any = [];
  allBrands: any = [];
  mapId: any;
  locationFromId: any;
  searchBy = 'showroom';
  locationToId: any;
  locationToPolyId: any;
  keyboard: Keyboard | any;
  searchTerm = '';
  debouncedSearch: any;
  @ViewChild('vs') vs!: VirtualScroller;
  @ViewChild('pDirectoryInput', { static: false }) pDirectoryInput!: InputTextModule | any;

  inBounds = true;
  myOutOfBounds = {
    top: false,
    right: false,
    bottom: false,
    left: false
  };
  draggable = true;
  loadingData = false;
  includeAmenity = ['information-counter', 'food-court', 'offices'];
  constructor(
    private commonService: CommonService,
    private router: Router,
    private cdr: ChangeDetectorRef,
    public route: ActivatedRoute,
    private messageService: MessageService
  ) {
    this.debouncedSearch = _.debounce(this.applyFilter, 200);
  }
  applyFilter() {
    if (this.vs) {
      this.vs.scrollToIndex(0, 'instant');
    }
    console.log('Serach:', this.searchTerm);
    // this.keyboard.setInput(this.searchTerm);
    this.search({ query: this.searchTerm });
  }
  async ngAfterViewInit(): Promise<void> {
    this.mapId = this.route.root.firstChild?.snapshot.paramMap.get('mapId');
    this.locationFromId = this.route.root.firstChild?.snapshot.paramMap.get('locationFromId');
    // const retselectedLocationData: any = localStorage.getItem('_locationDataOfAllBuilding');
    const retallLocationData: any = this.commonService.getAllBuildingLoc();
    this.allLocations = retallLocationData ? JSON.parse(retallLocationData) : [];
    this.setExtIdonLocations();
    console.log('allLocations:', this.allLocations);
    const retallBrandData: any = this.commonService.getAllBuildingBrands();
    this.allBrands = retallBrandData ? JSON.parse(retallBrandData) : [];
    this.setExtIdonBrands();
  }
  search(event: any) {
    this.loadingData = true; // Set loading flag
    const query = event.query.toLowerCase();
    /* Locations */
    if (!this.allLocations || !this.allLocations.length) {
      const retallLocationData: any = this.commonService.getAllBuildingLoc();
      this.allLocations = retallLocationData ? JSON.parse(retallLocationData) : [];
      this.setExtIdonLocations();
    }
    let filteredDataLoc = this.allLocations.filter((location: any) => {
      // Check if the location space includes the query
      const locationSpaceMatch = location?.space?.toLowerCase().includes(query.toLowerCase());
      // Check if the location name includes the query
      const locationNameMatch = location.name.toLowerCase().includes(query.toLowerCase());
      return locationNameMatch || locationSpaceMatch;
    });
    filteredDataLoc = this.searchBy === 'washroom' ? _.uniqBy(filteredDataLoc, 'id') : filteredDataLoc;
    console.log('filteredDataLoc', filteredDataLoc);
    this.suggestionLocations = _(filteredDataLoc)
      .filter((res: any) => {
        res.name = this.commonService.matchLocationDescription(res, 'framburg/thumprint/arroyo');
        if (this.searchBy === 'washroom') {
          return res.type === 'amenities' && res.name.toLowerCase().includes(this.searchBy);
        } else {
          return (
            (res.type !== 'amenities' && res.type !== 'waypoints') ||
            (res.type === 'amenities' && _.includes(this.includeAmenity, res.amenity))
          );
        }
      })
      .sortBy([location => location.name.toLowerCase()])
      .value();
    /* Brands */
    if (!this.allBrands || !this.allBrands.length) {
      // const retselectedLocationData: any = localStorage.getItem('_locationDataOfAllBuilding');
      const retallBrandData: any = this.commonService.getAllBuildingBrands();
      this.allBrands = retallBrandData ? JSON.parse(retallBrandData) : [];
      this.setExtIdonBrands();
    }
    const filteredDataBrand = this.allBrands.filter((location: any) => {
      const brandNameMatch =
        location.brandName?.toLowerCase().includes(query) ||
        location.name?.toLowerCase().includes(query) ||
        location.space?.toLowerCase().includes(query);
      return brandNameMatch;
    });
    // filteredData = _.uniqBy(filteredDataBrand, 'id');
    this.suggestionBrands = _(filteredDataBrand)
      .filter((res: any) => {
        if (this.searchBy === 'washroom') {
          return false;
        } else {
          return (
            (res.type !== 'amenities' && res.type !== 'waypoints') ||
            (res.type === 'amenities' && _.includes(this.includeAmenity, res.amenity))
          );
        }
      })
      .sortBy([location => location.name.toLowerCase()])
      .value();
    this.loadingData = false; // Clear loading flag
  }
  setExtIdonLocations() {
    this.allLocations = this.allLocations.reduce((acc: any[], location: any) => {
      if (location.spaceExternalD && location.spaceExternalD.length) {
        _.forEach(location.spaceExternalD, (space: any) => {
          acc.push({ ...location, space: `${location.mapGroupCode}-${space}`, polygonID: space });
        });
      } else {
        acc.push({ ...location, space: null, polygonID: null });
      }
      return acc;
    }, []);
  }
  setExtIdonBrands() {
    this.allBrands = this.allBrands.reduce((acc: any[], location: any) => {
      if (location.brandName !== null) {
        _.forEach(location.spaceExternalD, space => {
          acc.push({ ...location, space: `${location.mapGroupCode}-${space}`, polygonID: space });
        });
      }
      return acc;
    }, []);
  }
  onLocationSelect($event: any) {
    // console.log('$event:', $event)
    this.locationToId = $event.id;
    this.locationToPolyId = $event.polygonID;
    this.router.navigate(['/maps', this.mapId], {
      relativeTo: this.route,
      queryParams: { to: $event.id, polygonID: $event.polygonID }
    });
    this.commonService.setEndLocationRequest($event);
    this.isOpenSearch = false;
    this.searchTerm = '';
    // this.keyboard.destroy();
    console.log('onLocationSelect:', $event, this.suggestionLocations);
  }
  onWashRoomSelect() {
    // let retselectedLocationData: any = localStorage.getItem('_locationDataOfSameFloor');
    this.router.navigate(['/maps', this.mapId], {
      relativeTo: this.route
    });
    return;
    let retselectedLocationData: any = this.commonService.getSameFloorLoc();
    retselectedLocationData = JSON.parse(retselectedLocationData) ? JSON.parse(retselectedLocationData) : [];
    const toLocation = _.find(
      retselectedLocationData,
      (res: any) => res.type === 'amenities' && _.includes(_.toLower(res.name), _.toLower('washroom'))
    );
    if (toLocation) {
      this.locationToId = toLocation.id;
      this.router.navigate(['/maps', this.mapId], {
        relativeTo: this.route,
        queryParams: { to: toLocation.id }
      });
      this.commonService.setEndLocationRequest(toLocation);
    } else {
      this.messageService.clear();
      this.messageService.add({
        key: 'toast-searchbar',
        severity: 'error',
        summary: 'Error',
        detail: 'Unfortunately, there are no restroom available on this floor.'
      });
    }
    this.searchTerm = '';
    this.isOpenSearch = false;
    this.searchTerm = '';
  }
  onPageNavigationClick(page: any) {
    const queryParams: any = this.route.snapshot.queryParamMap;
    if (queryParams && queryParams?.params) {
      if (queryParams?.params?.to) {
        this.locationToId = queryParams?.params?.to;
      }
      if (queryParams?.params?.polygonID) {
        this.locationToPolyId = queryParams?.params?.polygonID;
      }
    }
    if (page === 'all-floors') {
      this.router.navigate(['/maps'], {
        relativeTo: this.route
      });
    } else {
      this.router.navigate(['/maps', this.mapId, page], {
        relativeTo: this.route
      });
    }
  }
  onResetMap() {
    this.locationToId = null;
    // this.router.onSameUrlNavigation = 'reload';
    this.router.navigate([`/maps/${this.mapId}`], { relativeTo: this.route }).then(() => {
      this.commonService.setResetMapEvent(true);
    });
    this.isOpenSearch = false;
    this.searchTerm = '';
  }
  openMenu() {
    // if (this.keyboard) {
    //   this.keyboard.destroy();
    // }
    if (this.isOpenMenu) {
      this.isOpenMenu = false;
      this.isOpenSearch = false;
    } else {
      this.isOpenMenu = true;
    }
  }
  onLocationClear() {
    this.keyboard.clearInput();
    this.search({ query: '' });
    this.pDirectoryInput.nativeElement.focus();
  }
  openSearch() {
    // if (this.keyboard) {
    //   this.keyboard.destroy();
    // }
    if (this.isOpenSearch) {
      this.isOpenSearch = false;
      this.searchTerm = '';
    } else {
      this.isOpenSearch = true;
      this.onDirectoryFilter();
      console.log('pDirectoryInput??', this.pDirectoryInput);
      return;
      setTimeout(() => {
        this.keyboard = new Keyboard({
          syncInstanceInputs: true,
          preventMouseDownDefault: true,
          physicalKeyboardHighlight: true,
          physicalKeyboardHighlightBgColor: '#0481c4',
          physicalKeyboardHighlightTextColor: '#ffffff',
          onChange: (input: any) => this.onChange(input),
          onKeyPress: (button: any) => this.onKeyPress(button),
          // useTouchEvents: true,
          // autoUseTouchEvents: true,
          mergeDisplay: true,
          layoutName: 'default',
          layout: {
            default: [
              '1 2 3 4 5 6 7 8 9 0 {backspace}',
              'q w e r t y u i o p {clear}',
              'a s d f g h j k l ? {shift}',
              '- z x c v b n m , . {arrowleft} {arrowright}',
              '{space}'
            ],
            shift: [
              '1 2 3 4 5 6 7 8 9 0 {backspace}',
              'Q W E R T Y U I O P {clear}',
              'A S D F G H J K L ? {shift}',
              '- Z X C V B N M , . {arrowleft} {arrowright}',
              '{space}'
            ],
            numbers: ['1 2 3', '4 5 6', '7 8 9', '{abc} 0 {backspace}']
          },
          display: {
            '{numbers}': '123',
            '{space}': '⚊',
            '{ent}': 'return',
            '{clear}': 'CLEAR',
            '{escape}': 'esc ⎋',
            '{tab}': 'tab ⇥',
            '{backspace}': '⌫',
            '{capslock}': 'caps lock ⇪',
            '{shift}': '⇧',
            // '{shift}': '⬆',
            '{controlleft}': 'ctrl ⌃',
            '{controlright}': 'ctrl ⌃',
            '{altleft}': 'alt ⌥',
            '{altright}': 'alt ⌥',
            '{metaleft}': 'cmd ⌘',
            '{metaright}': 'cmd ⌘',
            '{abc}': 'ABC',
            '{arrowleft}': '◀',
            '{arrowright}': '▶',
            '{mic}': '🎙️'
          },
          buttonTheme: [
            {
              class: 'space-btn',
              buttons: '{space}'
            },
            {
              class: 'long-btn',
              buttons: '{backspace} {mic} {clear} {shift}'
            },
            {
              class: 'clear-btn',
              buttons: '{clear}'
            },
            {
              class: 'arrow-btns',
              buttons: '{arrowleft} {arrowright}'
            }
          ]
        });
      });
    }
  }
  onChange = (input: string) => {
    this.pDirectoryInput.nativeElement.focus();
    console.log('??this.pDirectoryInput', this.pDirectoryInput.nativeElement.value, input, this.pDirectoryInput);
    this.pDirectoryInput.nativeElement.value = input;
    this.searchTerm = input;
    this.pDirectoryInput.nativeElement.dispatchEvent(new Event('input'));
    this.pDirectoryInput.nativeElement.dispatchEvent(new Event('focus'));
    this.pDirectoryInput.nativeElement.dispatchEvent(new Event('click'));
  };
  onKeyPress = (button: string) => {
    console.log('Button pressed', button);
    /**
     * If you want to handle the shift and caps lock buttons
     */
    let set_position = this.keyboard.getCaretPosition();
    const set_position_end = this.keyboard.getCaretPositionEnd();
    if (button === '{clear}') {
      this.clearPInput();
      set_position = 0;
    } else if (button === '{shift}' || button === '{lock}') {
      this.handleShift();
    } else if (button === '{numbers}' || button === '{abc}') {
      this.handleNumbers();
    } else if (button === '{arrowleft}' || button === '{backspace}') {
      set_position = set_position === set_position_end ? set_position - 1 : set_position;
      if (set_position < 0) {
        set_position = 0;
      }
    } else if (button === '{arrowright}') {
      if (this.pDirectoryInput.nativeElement.value && this.pDirectoryInput.nativeElement.value.length > set_position) {
        set_position = set_position + 1;
      }
    } else {
      set_position = set_position + 1;
    }
    setTimeout(() => {
      this.keyboard.setCaretPosition(set_position);
      this.pDirectoryInput.nativeElement.setSelectionRange(set_position, set_position);
      this.pDirectoryInput.nativeElement.focus();
    }, 15);
  };
  handleShift = () => {
    const currentLayout: any = this.keyboard.options.layoutName;
    const shiftToggle: any = currentLayout === 'default' ? 'shift' : 'default';

    this.keyboard.setOptions({
      layoutName: shiftToggle
    });
  };
  handleNumbers = () => {
    const currentLayout: any = this.keyboard.options.layoutName;
    const numbersToggle: any = currentLayout !== 'numbers' ? 'numbers' : 'default';

    this.keyboard.setOptions({
      layoutName: numbersToggle
    });
  };
  onDirectoryFilter() {
    this.pDirectoryInput.nativeElement.focus();
    this.search({ query: this.searchTerm });
    if (this.keyboard) {
      this.keyboard.setCaretPosition(this.searchTerm?.length);
      this.pDirectoryInput.nativeElement.setSelectionRange(this.searchTerm?.length, this.searchTerm?.length);
    }
  }
  clearPInput() {
    this.keyboard.clearInput();
    this.pDirectoryInput.nativeElement.value = null;
    // this.pDirectoryAutoComplete.inputFieldValue = null;
    // this.pDirectoryAutoComplete.inputValue = null;
    // this.pDirectoryAutoComplete.value = null;
    this.searchTerm = '';
    // this.pDirectoryAutoComplete.filled = false;
  }
}
